@import "react-toastify/dist/ReactToastify.css";

body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  --ck-z-default: 100;
  --ck-z-modal: calc(var(--ck-z-default) + 999);
}

body,
html,
#root {
  height: 100%;
  min-height: 100%;
}

.MuiDialog-root .MuiDialog-scrollPaper {
  display: block;
}

.modal-title-select-skill {
  color: white !important;
  background: #2f3a8f !important;
  margin-bottom: 1rem !important;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  margin: 5% auto;
}
.ck.ck-balloon-panel {
  z-index: 1300 !important;
}

.MuiDialog-paperFullScreen {
  margin: 0 !important;
}

.MuiGrid-spacing-xs-4 {
  margin: 0 -16px !important;
}

.MuiLinearProgress-barColorPrimary {
  color: #757575;
}

.btn-save-modal {
  background: green !important;
  color: white !important;
}

.btn-cancel-modal {
  background: red !important;
  color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

@media screen and (orientation: landscape) {
  #sideBarOpened {
    height: auto;
  }
}
